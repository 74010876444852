import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className, children, post, locationData = {}, locationPath = false, alt = "Home Image" }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiImages {
        nodes {
          FormBackgroundImage {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  const imageData = data.allStrapiImages.nodes[0].FormBackgroundImage?.localFile.childImageSharp.fluid;
  return (
    <BackgroundImage
      alt={alt}
      Tag="section"
      className={className}
      fluid={locationPath ? locationData.locationDetails.location_image?.localFile.childImageSharp.fluid : post ? post.blogImage?.localFile.childImageSharp.fluid : imageData}
      backgroundColor={`#040e18`}
    >
      {children}
    </BackgroundImage>
  )
}

export default BackgroundSection
