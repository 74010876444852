import React, { useEffect } from "react"
import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { FaForward } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaShuttleVan } from "react-icons/fa";
import ScrollReveal from 'scrollreveal'
import _isEmpty from "lodash/isEmpty";

import QuickTip from "./common/quickTip";
import Services from "./common/services"
import ButtonField from "./common/form/ButtonField"
import { ANIMATION_DURATION, PROCESS_SCREEN } from "../constant"

const SellHome = ({ locationData = {}, locationPath = false }) => {
  const introData = useStaticQuery(graphql`
    query {
      allStrapiIntroSection {
        nodes {
          Title
          Description
          step {
            title
            step {
              description
              title
              image {
                alternativeText
                localFile {
                  childImageSharp{
                    fluid(quality: 0, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          brandImage {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }  
          Intro_text
          stepsEnabled
          alterHeaderStep {
            description
            title
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          QuickTipsHelp {
            firstTitle
            secondTitle
            optionList{
              desc
            }
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            } 
            helpDescription {
              desc
            }
            optionListHeading
            optionDescription {
              desc
            }
          }   
          QuickTipsHelpEnabled
          alterHeaderEnabled
          alternateHeaderDesc
          alternateHeaderTitle
          animationEffect
        }
      }
      tarekPromise: file(relativePath: { eq: "tarek-promise.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  let data = {}
  let How_to_sell = {}
  let tarekPromise = introData.tarekPromise.childImageSharp.fluid

  if (locationPath) {
    data = locationData.locationDetails
    How_to_sell = !_isEmpty(data.How_do_sell[0]) ? data.How_do_sell[0] : locationData.allStrapiLocationCommonContent.nodes[0].How_do_sell[0]
  } else {
    data = introData.allStrapiIntroSection.nodes[0]
  }

  const { Description, Title, step, brandImage, stepsEnabled = true, Intro_text = true, QuickTipsHelp = [], QuickTipsHelpEnabled = true, alterHeaderEnabled = true, alternateHeaderTitle = "", alternateHeaderDesc = "", animationEffect = false } = data

  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.sellHomeAnime', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.pencilMoney', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.introText', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.serivesSection', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.QuickTipAnime', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.ourMissionSection', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])
  
  const lastWord = (text) =>{
    let splitArr=text.split(' ')
    let finalArr = splitArr.map((val,i)=>{
      return (i===splitArr.length-1)?`<span>${val}</span>`:val
    })
    let joinArr=finalArr.join(" ");
    return joinArr
  }
  const introText = (tit = Title, desc = Description, altHeaderDesc = "", altHeaderTitle = "",lastWordStyle=false) => {
    if(lastWordStyle){
      tit=lastWord(tit)
    }
    return <div className="d-flex flex-column align-items-center introText" >
      <h2 className={`text-center sellHomeTitle ${altHeaderTitle}`} dangerouslySetInnerHTML={{__html: tit }} ></h2>
      <div className={`sellHomePara text-center ${altHeaderDesc}`}>
        <ReactMarkdown>
          {desc}
        </ReactMarkdown>
      </div>
    </div>
  }
  return (
    <div className="sellHomeContainer">
      <div className="image-content">
        <div className="d-flex max2000 flex-wrap">
          {brandImage.map((val, i) => {
            return (
              <div
                key={i}
                className="col-4 col-sm-2 d-flex justify-content-center z-1 p-0"
              >
                <Img fluid={val.image.localFile.childImageSharp.fluid} alt={val.image?.alternativeText} className="sellhomeLogos" />
              </div>
            )
          })}
        </div>
      </div>
      {!locationPath && alterHeaderEnabled && <div className="d-flex flex-column align-items-center sellHomeContent pt-5 px-3 pb-0">
        <div className="sellHomeText sellHomeAnime pb-0">
          {introText(alternateHeaderTitle, alternateHeaderDesc, "altHeaderDesc", "altHeaderTitle", true)}
        </div>
        {/* <div className="d-flex justify-content-around pencilMoney pt-3">
          {alterHeaderStep.map((val, i) => {
            return <div className="text-center element" key={i}>
              <Img fluid={val.image.childImageSharp.fluid} className="iconStyle mx-auto" />
              <div className="number">{val.title}</div>
              <div className="helpDes">
                <ReactMarkdown>
                  {val.description}
                </ReactMarkdown>
              </div>
            </div>
          })}
        </div> */}
      </div>}
      <div className={`d-flex justify-content-center sellHomeContent ${locationPath ? "py-4" : "pt-4"}`}>
        <div className={`sellHomeText ${locationPath ? "" : "pt-0 pb-5"}`}>
          {locationPath ? introText() : Intro_text && introText()}
          {/* {
            locationPath ? <Services data={How_to_sell.step} title={How_to_sell.title} description={true} locationPath={locationPath} /> :
              stepsEnabled && <Services data={step[0].step} title={step[0].title} description={true} />
          } */}
          {
            locationPath ? <div className="ourMissionSection">
              <div className={`width`}>
                <h2>{How_to_sell.title}</h2>
                <div className="d-flex justify-content-around align-items-baseline mb-4 columnFlex">
                  {How_to_sell.step.map((val, i) => {
                    return <div
                      className={`mx-2 mt-4 d-flex flex-column align-items-center"`}
                      key={i}
                    >
                      <div className="d-flex flex-column align-items-center ">
                        {
                          <div className="iconCircle">
                            {
                              <div className="d-flex circleImage justify-content-center align-items-center">{i === 0 ?
                                <FaPhone size={40} /> : i === 1 ? <RiMoneyDollarBoxLine size={40} /> : i === 2 ?
                                  <FaForward size={40} /> : <FaShuttleVan size={40} />}
                              </div>
                            }
                          </div>
                        }
                        <div className="titleText text-center mt-2">{i + 1}. {val.title}</div>
                        {<div className={`ourMissionDes mb-4 text-center WhyDoISell`}>
                          {val.description}
                        </div>}
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </div> :
              stepsEnabled && <div className="serivesSection">
                <Services data={step[0].step} title={step[0].title} description={true} animationEffect={animationEffect} homepage={true} />
                <hr className="PromiseUnderline" />
                <div className="d-flex ">
                  <Link to={PROCESS_SCREEN} className="tarekPromise" >
                    <Img fluid={tarekPromise} className="tarekPromiseImg mb-1" alt="Tarek promise" />
                  </Link>
                </div>
              </div>
          }
          <div className="text-center pb-5">
            <ButtonField className="LearnMoreBtn" to={PROCESS_SCREEN} name="Learn More" /* onClick={() => onRedirect()} */ />
          </div>
        </div>
      </div>
      {locationPath ? "" :
        <div className="QuickTipAnime"><QuickTip QuickTipsHelpEnabled={QuickTipsHelpEnabled} QuickTipsHelp={QuickTipsHelp[0]} animationEffect={animationEffect} /></div>}
    </div>
  )
}
export default SellHome