import React, { useState, useEffect } from "react"
import ScrollReveal from 'scrollreveal'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import { YoutubeModal } from "./youTubeModal"
import { ANIMATION_DURATION } from "../../constant"

const QuickTip = ({
  QuickTipsHelp = {},
  QuickTipsHelpEnabled = true,
  locationPath = false,
  locationData = {},
  animationEffect = false
}) => {
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.descriptionAnime', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.flipFlapSection', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.quickTipContainer', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])

  return (
    QuickTipsHelpEnabled && (<div className="sellHomeContainer" style={{ backgroundColor: locationPath ? "#f5f5f5" : "" }}>
      {(!locationPath || (locationPath && !locationData.locationDetails.alterSellYouHome)) ? <div className="max2000 py-5 px-5 quickTipContainer quickTipDiv">
        <h2 className={`quickTips text-center ${locationPath ? "locationQuickTips" : ""}`}>{locationPath ? QuickTipsHelp.Title : QuickTipsHelp.firstTitle}</h2>
        {locationPath ? "" : <h2 className="quickTipsTitle text-center pt-2 pb-4">
          {QuickTipsHelp.secondTitle}
        </h2>}
        <div className="d-flex align-items-center quickTipFlex">
          <div className="quickTipsDes" style={{ width: "100%" }}>
            {locationPath ? <div>
              <ReactMarkdown>{QuickTipsHelp.shortDescription}</ReactMarkdown>
            </div> :
              QuickTipsHelp.helpDescription.map((val, i) => {
                return (
                  <div key={i} className={`${i === 1 ? "mt-4" : ""}`}>
                    <ReactMarkdown>{val.desc}</ReactMarkdown>
                  </div>
                )
              })
            }
          </div>
          {locationPath ? (
            <div className="locationQuickTip">
              <div className="video-content">
                <iframe
                  title="iframe"
                  className="videoRadio"
                  srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style><a ><img src=https://img.youtube.com/vi/${QuickTipsHelp.youTubeVideoURL}/hqdefault.jpg </a>`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <span
                  role="none"
                  onKeyDown={() => ""}
                  onClick={() => {
                    setVideoUrl(`https://www.youtube.com/embed/${QuickTipsHelp.youTubeVideoURL}`)
                    setModalShow(true)
                  }}
                  className="playBotton cursor-pointer"
                >
                  ▶
                </span>
              </div>
            </div>
          ) : (
            <Img
              fluid={QuickTipsHelp.image.localFile.childImageSharp.fluid}
              className="sellFastimag"
                alt={QuickTipsHelp.image?.alternativeText}
            />
          )}
        </div>
        <div className="descriptionAnime">
          {!locationPath && <h5>
            {QuickTipsHelp.optionListHeading}
          </h5>}
          <ul className={`${locationPath ? "quickTipsDes m-0" : "ul"}`}>
            {locationPath ? <div>
              <ReactMarkdown>{QuickTipsHelp.Description}</ReactMarkdown>
            </div> :
              QuickTipsHelp.optionList.map((val, i) => {
                return (
                  <li key={i} className="py-1">
                    <span className={` ${locationPath ? "locationOptions" : "options"}`}>
                      <ReactMarkdown>{val.desc}</ReactMarkdown>
                    </span>
                  </li>
                )
              })
            }
          </ul>
          {locationPath ? null :
            QuickTipsHelp.optionDescription.map((val, i) => {
              return (
                <div key={i} className={`quickTipsDes p-0 ${i === 1 ? "pb-4 pt-2" : ""}`}>
                  <ReactMarkdown>{val.desc}</ReactMarkdown>
                </div>
              )
            })
          }
        </div>
        <YoutubeModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          videoUrl={videoUrl}
          className="ourProcessModel"
        />
      </div> : null}
      {locationPath && locationData.locationDetails.alterSellYouHome && <div className="max2000 py-5 px-4 quickTipContainer quickTipDiv">
        <h2 className="quickTips text-center locationQuickTips mb-2">{locationData.locationDetails.alterlocationSell.Title}</h2>
        <div className="quickTipsDes locationTipDes text-center mb-4">{locationData.locationDetails.alterlocationSell.description}</div>
        <div className="row row-cols-4 quickTipImages justify-content-center">
          {(locationData.locationDetails.alterlocationSell.locationImage).map((data, i) =>
            <div className="d-flex flex-column align-items-center mb-5" key={i}>
              <div className="frame">
                <Img
                  fluid={data.image?.localFile.childImageSharp.fluid}
                  className="locationTipImage"
                  alt={data.image?.alternativeText}
                />
              </div>
              <div className="quickTipImgTitle text-center font-weight-bold mt-2">{data.title}</div>
            </div>
          )}
        </div>
        <div className="quickTipsDes text-center belowDescription">{locationData.locationDetails.alterlocationSell.belowDescription}</div>
      </div>}
    </div>)
  )
}

export default QuickTip
