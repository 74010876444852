import React, { useEffect, useState, useCallback } from "react"
import { graphql, navigate, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import _debounce from "lodash/debounce"
import _forEach from "lodash/forEach"
import _isEmpty from "lodash/isEmpty"
import { Nav, Navbar } from "react-bootstrap"
import { IoCall } from "react-icons/io5"
import { useLocation } from "@reach/router"
import * as queryString from "query-string"

import ButtonField from "./common/form/ButtonField"
import { BLOG_SCREEN, HOME_SCREEN, PROCESS_SCREEN, STEP_SECOND_SCREEN, FAQ_SCREEN, initialFormValues, ABOUT_US } from "../constant"
import { addressDisplay, getStorage, setStorage } from "../utils/index"

const Header = ({ title }) => {
  const [scrollValue, setScrollValue] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [activeKey, setActiveKey] = useState("1")
  const location = useLocation()
  useEffect(() => {
    let activeBlog = ["Category", "singlePost"]
    if (location.pathname.includes(BLOG_SCREEN) || activeBlog.includes(title)) {
      setActiveKey("4")
    } else if (location.pathname.includes(PROCESS_SCREEN)) {
      setActiveKey("3")
    } else if (location.pathname.includes(FAQ_SCREEN)) {
      setActiveKey("5")
    } else if (location.pathname.includes(ABOUT_US)) {
      setActiveKey("2")
    } else {
      setActiveKey("1")
    }
  }, [location.pathname, title])
  useEffect(() => {
    // let faqValue = getStorage("faq") ?? null
    // if (faqValue) {
    //   onFaqScroll()
    //   setStorage("faq", false)
    // }
  }, []);

  useEffect(() => {
    let initalValue = getStorage("formData")
    let query = queryString.parse(location.search)
    let finalValue = { ...initialFormValues, ...initalValue, ...query }
    setStorage("formData", finalValue)
  }, [location.search])

  const onChangeText = e => {
    setStorage("addressSelect", false)
    setSearchText(e.target.value)
  }
  useEffect(() => {
    if (document.getElementById("pac-input1")) {
      document.getElementById("pac-input1").value = searchText
    }
  }, [searchText])

  const checkOffset = _debounce(function () {
    let temp = window.scrollY
    let autocomplete_results_all = document.querySelectorAll(
      ".autocomplete-results"
    )
    let autocomplete_results_all_header = document.querySelectorAll(
      ".autocomplete-results2"
    )
    if (temp >= 800 && !scrollValue) {
      _forEach(autocomplete_results_all_header, autocomplete_results => {
        autocomplete_results.style.display = "none"
      })
      setScrollValue(true)
      return
    } else if (temp < 800 && scrollValue) {
      _forEach(autocomplete_results_all, autocomplete_results => {
        autocomplete_results.style.display = "none"
      })
      setScrollValue(false)
      return
    }
  }, 100)
  const onscrollOffset = useCallback(() => {
    checkOffset()
  }, [checkOffset])

  useEffect(() => {
    document.addEventListener("scroll", onscrollOffset)
    return () => {
      document.removeEventListener("scroll", onscrollOffset)
    }
  }, [scrollValue, onscrollOffset])

  useEffect(() => {
    let callRequest = document.getElementById('phoneNo')
    callRequest.addEventListener("click", () => {
      let isAvailable = document.getElementById('trackingPixel')
      let trackScript = document.createElement("script")
      trackScript.id ="trackScript"
      trackScript.appendChild(document.createTextNode(`new Image(1, 1).src = "//ad.ipredictive.com/d/track/cvt/pixel?acct_id=57298&cache_buster="+Math.floor(Date.now()/1000)`))
      function setAttributes(el, attrs) {
        for (var key in attrs) {
          el.setAttribute(key, attrs[key]);
        }
      }
      var parent = document.createElement("noscript");
      parent.id = "parent";
      parent.appendChild(document.createElement("img"));
      setAttributes(parent.firstChild, { 
        "src": `//ad.ipredictive.com/d/track/cvt/pixel?acct_id=57298&cache_buster=${Math.floor(Date.now() / 1000)}`, 
        "height": "1", 
        "width": "1", 
        "alt": "phone", 
        "style": "display: none", 
        "id":"trackingPixel"
      })
      if (isAvailable){
        let noScript = document.getElementById('parent')
        let script = document.getElementById('trackScript')
        noScript.remove();
        script.remove();
      }
      document.body.append(parent)
      document.head.append(trackScript)
    }
    )
  },[])

  const debounce = (func, wait, immediate) => {
    let timeout
    return function () {
      let context = this,
        args = arguments
      let later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      let callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }
  const onSetValue = place => {
    if (!_isEmpty(place)) {
      let obj = {}
      obj = {
        city: "",
        google_county: "",
        latitude: place.geometry?.location.lat(),
        longitude: place.geometry?.location.lng(),
        state: "",
        street_address: place.name,
        zip: "",
      }
      place.address_components.map(val => {
        if (val.types.includes("locality")) {
          obj = {
            ...obj,
            city: val.short_name,
          }
        }
        if (val.types.includes("administrative_area_level_2")) {
          obj = {
            ...obj,
            google_county: val.short_name,
          }
        }
        if (val.types.includes("administrative_area_level_1")) {
          obj = {
            ...obj,
            state: val.short_name,
          }
        }
        if (val.types.includes("postal_code")) {
          obj = {
            ...obj,
            zip: val.short_name,
          }
        }
        return obj
      })
      let initalValue = getStorage("formData")
      const { street_address = " ", city = " ", state = " ", zip = " " } = obj
      setStorage("formData", {
        ...initalValue,
        ...obj,
        address: `${addressDisplay(street_address)} ${addressDisplay(city)} ${addressDisplay(state)} ${zip} USA`,
        unit: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        currently_listed: null,
        require_repairs: null,
        current_condition: null,
        asking_price: null,
        urgency: null,
      })
      let selectValue = getStorage("addressSelect")
      if (!selectValue) {
        navigate(STEP_SECOND_SCREEN)
      }
    }
  }
  useEffect(() => {
    const input = document.getElementsByName("getOfferText")
    setTimeout(() => {
      if (typeof window.google !== "undefined") {
        _forEach(input, item => {
          if (item) {
            let map = new window.google.maps.Map(
              document.getElementById("map"),
              {
                center: {
                  lat: 48,
                  lng: 4,
                },
                zoom: 4,
                disableDefaultUI: true,
              }
            )
            let autocomplete_results_all = document.querySelectorAll(
              ".autocomplete-results2"
            )
            let predictValue = []
            _forEach(autocomplete_results_all, autocomplete_results => {
              let service = new window.google.maps.places.AutocompleteService()
              let serviceDetails = new window.google.maps.places.PlacesService(
                map
              )
              let displaySuggestions = function (predictions, status) {
                if (
                  status !== window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  predictValue = []
                }
                let results_html = []
                predictValue = predictions
                if (!_isEmpty(predictValue)) {
                  predictions.forEach(function (prediction) {
                    results_html.push(
                      `<li class="autocomplete-item" data-type="place" data-place-id=${prediction.place_id}><span class="autocomplete-text">${prediction.description}</span></li>`
                    )
                  })
                }
                autocomplete_results.innerHTML = results_html.join("")
                autocomplete_results.style.display = "block"
                let autocomplete_items = autocomplete_results.querySelectorAll(
                  ".autocomplete-item"
                )
                let getBtns = document.getElementsByName("getOfferBtnHeader")
                _forEach(getBtns, getBtn => {
                  getBtn.addEventListener("click", () => {
                    let request = {
                      placeId: predictValue[0].place_id,
                      fields: ["name", "geometry", "address_components"],
                    }
                    serviceDetails.getDetails(
                      request,
                      function (place, status) {
                        if (
                          status ===
                          window.google.maps.places.PlacesServiceStatus.OK
                        ) {
                          if (!place.geometry) {
                            console.log("Returned place contains no geometry")
                            return
                          } else {
                            let selectValue = getStorage("addressSelect")
                            let textValue = getStorage("addressText")
                            if (textValue) {
                              if (selectValue) {
                                navigate(STEP_SECOND_SCREEN)
                              } else {
                                onSetValue(place)
                                setSearchText(predictValue[0].description)
                                item.value = predictValue[0].description
                              }
                            }
                          }
                        }
                        autocomplete_results.style.display = "none"
                      }
                    )
                  })
                })
                for (let autocomplete_item of autocomplete_items) {
                  autocomplete_item.addEventListener("click", function () {
                    const selected_text = this.querySelector(
                      ".autocomplete-text"
                    ).textContent
                    const place_id = this.getAttribute("data-place-id")
                    let request = {
                      placeId: place_id,
                      fields: ["name", "geometry", "address_components"],
                    }
                    serviceDetails.getDetails(
                      request,
                      function (place, status) {
                        if (
                          status ===
                          window.google.maps.places.PlacesServiceStatus.OK
                        ) {
                          if (!place.geometry) {
                            console.log("Returned place contains no geometry")
                            return
                          } else {
                            setStorage("addressSelect", true)
                            onSetValue(place)
                          }
                        }
                        setSearchText(selected_text)
                        item.value = selected_text
                        autocomplete_results.style.display = "none"
                      }
                    )
                  })
                }
              }
              item.addEventListener(
                "input",
                debounce(function () {
                  let value = this.value
                  let pattern = /.*[0-9].*/g
                  let testReg = pattern.test(value)
                  value.replace('"', '\\"').replace(/^\s+|\s+$/g, "")
                  if (value !== "" && testReg) {
                    service.getPlacePredictions(
                      {
                        input: value,
                        componentRestrictions: { country: "us" },
                        fields: [
                          "address_components",
                          "geometry",
                          "icon",
                          "name",
                        ],
                      },
                      displaySuggestions
                    )
                    setStorage("addressText", true)
                  } else {
                    setStorage("addressText", false)
                    autocomplete_results.innerHTML = ""
                    autocomplete_results.style.display = "none"
                  }
                }, 150)
              )
            })
          }
        })
      }
    }, 1000)
  }, [])

  const handleSelect = eventKey => setActiveKey(eventKey)
  const onBlur = () => {
    let autocomplete_results_all = document.querySelectorAll(
      ".autocomplete-results2"
    )
    setTimeout(() => {
      _forEach(autocomplete_results_all, autocomplete_results => {
        autocomplete_results.style.display = "none"
      })
    }, 400)
  }

  // scroll to home screen faq section now no need
  // const onFaqScroll = () => {
  //   navigate(HOME_SCREEN)
  //   setTimeout(() => {
  //     var my_element = document.getElementById("offsetFAQ")
  //     window.scroll({
  //       top: my_element?.offsetTop - 70,
  //       behavior: "smooth",
  //     })
  //   }, 200)
  // }

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      headerCert: file(relativePath: { eq: "header-cert.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const onRedirect = (focus = false) => {
    setActiveKey("1")
    setStorage("focusValue", focus)
  }

  const onPhoneClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "phone_click",
      dtL_phone_click: {
        branch: process.env.GATSBY_BRANCH
      },
    })
  }

  const imageDataLogo = data.logo.childImageSharp.fluid
  const headerCert = data.headerCert.childImageSharp.fluid
  return (
    <header>
      <div id="map" className="d-none"></div>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className={`p-0 ${scrollValue ? "" : ""}`}
        style={{ backgroundColor: "#fff" }}
      >
        <Navbar.Brand className="text-dark p-0 logoNavBar">
          <div className="d-flex flex-row align-items-center">
            <Link
              to={HOME_SCREEN}
              onClick={() => onRedirect()}
              className="cursor-pointer headerLogo"
            >
              <Img fluid={imageDataLogo} className="logoSize"  alt="logo" />
            </Link>
            <div className="text-white p-3 px-4 display-hide phone-out">
              <div className="d-flex align-items-center phone-in">
                <IoCall color={"#d9821b"} size={30} className="mr-2" />
                <a
                  id="phoneNo"
                  className="text-white cursor-pointer"
                  href="tel:888-356-3547"
                  onClick={() => onPhoneClick()}
                >
                  {"(888) 356-3547"}
                </a>
              </div>
            </div>
          </div>
        </Navbar.Brand>
        <div className="phoneContainer">
          <div className="callLogo cursor-pointer">
            <IoCall color={"white"} className=" callSymbol" />
          </div>
          <a className="phoneBtn" href="tel:888-356-3547" onClick={() => onPhoneClick()}>
            {"(888) 356-3547"}
          </a>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="d-flex header-section text-center w-100 mr-2"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Nav.Link as={Link} to={ABOUT_US} className="header-text" eventKey="2">
              <div className="skew">ABOUT US</div>
            </Nav.Link>
            <Nav.Link as={Link} to={PROCESS_SCREEN} className="header-text" eventKey="3">
              <div className="skew">OUR PROCESS</div>
            </Nav.Link>
            <Nav.Link
              href={BLOG_SCREEN}
              // as={Link}
              // to={BLOG_SCREEN}
              className="header-text"
              eventKey="4"
            >
              <div className="skew">BLOG</div>
            </Nav.Link>
            <Nav.Link
              className="header-text mr"
              eventKey="5"
              as={Link}
              to={FAQ_SCREEN}
              // onClick={() => onFaqScroll()}
            >
              <div className="skew">FAQ</div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Link
          to={HOME_SCREEN}
          className="header-get-offer btnHover text-white display-hide"
          onClick={() => onRedirect(true)}
        >
          GET MY FREE OFFER
        </Link>
        <Img
          fluid={headerCert}
          className="display-hide headercertImg mx-3"
          width={110}
          height={70}
          alt="rating"
        />
      </Navbar>
      <Navbar
        collapseOnSelect
        expand="true"
        variant="light"
        className={`p-0 floatingInput w-100 ${!scrollValue ? "d-none" : ""}`}
        style={{ backgroundColor: "#fff" }}
      >
        <Navbar.Brand className="text-dark p-0 w-85">
          <div className="d-flex flex-row align-items-center">
            <Link
              to={HOME_SCREEN}
              onClick={() => onRedirect()}
              className="cursor-pointer ml-md-3 w-15 headerLogo"
            >
              <Img fluid={imageDataLogo} className="logoSize" alt="logo" />
            </Link>
            <div className="text-dark p-3 px-4 display-hide w-30">
              <div className="d-flex align-items-center">
                <IoCall className="mr-2" color={"#d9821b"} size={30} />
                <a
                  className="cursor-pointer numberHover"
                  href="tel:888-356-3547"
                  onClick={() => onPhoneClick()}
                >
                  {"(888) 356-3547"}
                </a>
              </div>
            </div>
            <div className="position-relative display-hide w-55">
              <ButtonField
                className="btn-primary getOfferBtn "
                id="getOfferBtn"
                fieldName="getOfferBtnHeader"
                name="GET MY FREE OFFER"
              />
              <input
                id="pac-input2"
                type="text"
                placeholder="Enter your home address"
                className="form-control form-getAddressFixedInput shadow-sm"
                name="getOfferText"
                onChange={e => onChangeText(e)}
                autoComplete="off"
                onBlur={() => onBlur()}
              />
              <ul className="autocomplete-results2"></ul>
            </div>
          </div>
        </Navbar.Brand>
        <div className="phoneContainer">
          <div className="callLogo cursor-pointer">
            <IoCall color={"white"} className=" callSymbol" />
          </div>
          <a className="phoneBtn" href="tel:888-356-3547" onClick={() => onPhoneClick()}>
            {"(888) 356-3547"}
          </a>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="d-flex header-section-sticky text-center w-100 justify-content-around"
            activeKey={activeKey}
          >
            <Nav.Link className="header-text-sticky" eventKey="2" as={Link} to={ABOUT_US}>
              ABOUT US
            </Nav.Link>
            <Nav.Link className="header-text-sticky" eventKey="3" as={Link} to={PROCESS_SCREEN}>
              OUR PROCESS
            </Nav.Link>
            <Nav.Link
              className="header-text-sticky"
              eventKey="4"
              href={BLOG_SCREEN}
            // as={Link}
            // to={BLOG_SCREEN}
            >
              BLOG
            </Nav.Link>
            <Nav.Link
              className="header-text-sticky"
              eventKey="5"
              as={Link}
              to={FAQ_SCREEN}
              // onClick={() => onFaqScroll()}
            >
              FAQ
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}
export default Header
