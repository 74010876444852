import * as React from "react"
import { Modal } from "react-bootstrap"

export const YoutubeModal = props => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="testimonialsArea">
        <iframe
          title="iframe"
          className="mb-0 videoRadio"
          src={props.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  )
}
