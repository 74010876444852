import React, { useState, useEffect } from "react"
import { graphql, navigate, useStaticQuery, Link } from "gatsby"
import { FaYoutube, FaTwitter } from "react-icons/fa"
import { FiInstagram } from "react-icons/fi"
import { ImFacebook } from "react-icons/im"
import Img from "gatsby-image"
import _kebabCase from "lodash/kebabCase"
import BackgroundImage from "gatsby-background-image"
// import _times from "lodash/times"
import ScrollReveal from "scrollreveal"
import _includes from "lodash/includes"
import { IoCall } from "react-icons/io5"

import { setStorage } from "../utils/index"
import ButtonField from "./common/form/ButtonField"
import NewsLetter from "../components/newsLetter"
import { ANIMATION_DURATION, HOME_SCREEN, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../constant"

const Footer = ({
  title,
  animationEffect = false,
  locationSectionAnimation = false,
  homepage = false
}) => {
  const titleArray = ["get-offer-no-background", "get-offer"]
  const [scrollFooter, setScrollFooter] = useState(false)
  const [wiggleFooter, setWiggleFooter] = useState(false)
  const [stickyFooter, setStickyFooter] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      let temp = window.scrollY
      if (temp >= 800) {
        setStickyFooter(true)
      } else if (temp < 800) {
        setStickyFooter(false)
      }
      if (temp === 0) {
        setScrollFooter(true)
      } else {
        setScrollFooter(false)
      }
    });
    return () => {
      window.removeEventListener("scroll", () => { })
      setStickyFooter({})
    }
  }, [])

  useEffect(() => {
    if (wiggleFooter && scrollFooter) {
      document.getElementById("pac-input1").focus();
      var element = document.getElementById('pac-input1');
      element.classList.add('wobble');
      setStorage("focusValue", false)
      setTimeout(() => {
        element.classList.remove('wobble');
      }, 500);
      setScrollFooter(false)
      setWiggleFooter(false)
      return () => {
        setWiggleFooter(false)
        setScrollFooter(false)
      };
    }
  }, [wiggleFooter, scrollFooter])

  const moveTop = () => {
    if (titleArray.includes(title)) {
      navigate(HOME_SCREEN)
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      })
    }
  }
  const stickyFooterBtn = () => {
    if (homepage) {
      window.scroll({
        top: 0,
        behavior: "smooth",
      })
      var element = document.getElementById('pac-input1');
      element.classList.add('wobble');
      setStorage("focusValue", false)
      setTimeout(() => {
        element.classList.remove('wobble');
      }, 500);
      setWiggleFooter(true)
    } else {
      navigate(HOME_SCREEN)
    }
    setStorage("focusValue", true)
  }
  useEffect(() => {
    let val = ["Blog", "Category", "singlePost"]
    if (!_includes(val, title) && locationSectionAnimation) {
      ScrollReveal().reveal(".location-right", { delay: ANIMATION_DURATION })
      ScrollReveal().reveal(".box", {
        duration: 500,
        distance: "100%",
        origin: "left",
        easing: "ease-out",
      })
    }
  }, [title, locationSectionAnimation])

  const data = useStaticQuery(graphql`
    query {
      locationList: allStrapiLocations(sort: {fields: published_at}) {
        nodes {
          location_name
          location_image {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          helped_over
          slug
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      locationMap: file(relativePath: { eq: "location-map.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const onPhoneClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "phone_click",
      dtL_phone_click: {
        branch: process.env.GATSBY_BRANCH,
      },
    })
  }
  const BGimage = props => {
    return (
      <Link
        to={`/${_kebabCase(props.slug.toLowerCase())}/`}
        className="col-12 col-md-4 p-0 cursor-pointer mb-3"
      >
        <BackgroundImage className="locationImg" fluid={props.image} alt={props.alt} >
          <span
            // to={`/${_kebabCase(props.slug.toLowerCase())}/`}
            className="flexCenter text-white text-center"
            style={{
              height: "100%",
              backgroundColor: " rgba(216, 130, 40, 0.8)",
            }}
          >
            {props.name}
          </span>
        </BackgroundImage>
      </Link>
    )
  }
  const location = data.locationList.nodes
  const imageDataLogo = data.logo.childImageSharp.fluid
  // const imageDataLocationMap = data.locationMap.childImageSharp.fluid
  return (
    <div className=" d-flex flex-column footerContainer">
      {titleArray.includes(title) ? (
        ""
      ) : (
        <>
          <div className="flexCenter flex-column locationContent">
            {/* map section as of now hided */}
            {/* <div className="mapContainer">
              <div className="ourLocation text-center mb-4">
                We are buying houses in 20+ markets!
              </div>
              <div className="max2000 p-5 d-flex locationMapContent justify-content-between">
                <div className="locationMap position-relative">
                  <Img fluid={imageDataLocationMap} alt="Location Map"/>
                  <div className="location-left">
                    {_times(21, key => (
                      <div id={`pin-${key}`} className="box" key={key}></div>
                    ))}
                  </div>
                </div>
                <div className="locationMap location-right ml-5">
                  <h6 className="font-weight-bold">
                    Adding new markets monthly...
                  </h6>
                  <span className="locationDes">
                    Tarek Buys Houses purchases distressed homes and homes that
                    are in need of repair, and we purchase in multiple markets
                    nationwide! We add new buyers and new markets each month. Do
                    you have a home to sell? No matter how bad the condition,
                    chances are,{" "}
                    <span className="font-weight-bold">we’ll buy it!</span>
                  </span>
                </div>
              </div>
            </div> */}
            <div className="ourLocation text-center mb-4">Featured Markets</div>
            <div className="d-flex flex-wrap row locationDiv px-3 justify-content-evenly w-100">
              {location.map((data, i) => {
                return (
                  <BGimage
                    image={data.location_image?.localFile.childImageSharp.fluid}
                    alt={data.location_image?.alternativeText}
                    name={data.location_name}
                    slug={data.slug}
                    key={i}
                  />
                )
              })}
            </div>
          </div>
          {title !== "singlePost" && (
            <NewsLetter title={title} animationEffect={animationEffect} />
          )}
        </>
      )}
      <div className={`d-flex justify-content-between footerTap ${stickyFooter? 'footerBottom': ''}`}>
        <div className="d-flex justify-content-around footerFirstHalf">
          <Link
            to={HOME_SCREEN}
            className="px-2 footerImage cursor-pointer"
          >
            <Img fluid={imageDataLogo} className="logoSize" alt="logo" />
          </Link>
          <div className="text-dark socialMedia">
            <div className="text-white socialMediaInline phone-out">
              <div className="d-flex align-items-center phone-in justify-content-around mx-3">
                <a
                  href="https://www.facebook.com/tarekbuyshouses"
                  target="blank"
                  className="socialMediaHover"
                >
                  <ImFacebook size={30} className="cursor-pointer facebook" />
                </a>
                <a
                  href="https://www.instagram.com/tarekbuyshousesllc"
                  target="blank"
                  className="socialMediaHover ml-4"
                >
                  <FiInstagram size={30} className="insta cursor-pointer" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCoiVtulsxlKfSPb1QsuUcMQ"
                  target="blank"
                  className="socialMediaHover ml-4"
                >
                  <FaYoutube size={30} className="uTube cursor-pointer" />
                </a>
                <a
                  href="https://twitter.com/tarekbuyshouses"
                  target="blank"
                  className="socialMediaHover ml-4"
                >
                  <FaTwitter size={30} className="twitter cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between footerSecomdHalf">
          <div className="footerText text-center">
            <div>
              © Tarek Buys Houses, LLC | 4411 E La Palma Ave,
              Anaheim, CA 92807
            </div>
            <Link
              to={PRIVACY_POLICY}
              className="privacy-text"
            >
              Privacy Policy
            </Link>
            <Link
              to={TERMS_AND_CONDITIONS}
              className="privacy-text ml-3"
            >
              Terms and Conditions
            </Link>
          </div>
          <ButtonField
            className="btn-primary text-center p-2 buttonStyle bottom-btn font-weight-bold"
            name={titleArray.includes(title) ? "BACK TO HOME" : "BACK TO TOP"}
            type='submit'
            onClick={() => moveTop()}
          />
        </div>
      </div>
      {stickyFooter && <div className="stickyFooter">
        <div className="d-flex align-items-center justify-content-around stickyFooterContent">
          <a
            className="d-flex align-items-center py-3"
            href="tel:888-356-3547"
            onClick={() => onPhoneClick()}
          >
            <IoCall className="mr-2" color={"#d9821b"} size={30} />
            <div className="numberHover">{"(888) 356-3547"}</div>
          </a>
          <ButtonField
            className="btn-primary text-center p-2 bottom-btn stickyFooterBtn font-weight-bold"
            name={"GET OFFER"}
            onClick={() => stickyFooterBtn()}
          />
        </div>
      </div>
      }
    </div>
  )
}

export default Footer
