import React from "react"
import { useState, useEffect } from "react";
import loadable from '@loadable/component'

const Odometer = loadable(() => import('../common/Odometer'))

const GreatTimeToSell = ({ locationData = {} }) => {
   const [houseSold, sethouseSold] = useState(0)
   const [averageHome, setaverageHome] = useState(0)
   const [afterRehab, setafterRehab] = useState(0)

   const { title, description, stat } = locationData.locationDetails.greatTime
   useEffect(() => {
      let value = [".numberText1", ".numberText2", ".numberText3"]
      value.forEach((val, key) => {
         const box = document.querySelector(val);
         window.addEventListener("scroll", (event) => {
            if (isElementXPercentInViewport(box, 20)) {
               if (key === 0) {
                  sethouseSold(stat[0].title)
               } else if (key === 1) {
                  setaverageHome(stat[1].title)
               } else {
                  setafterRehab(stat[2].title)
               }

            }
         }, {
            passive: true
         })
      })
      return () => {
         window.removeEventListener("scroll", () => { })
         sethouseSold({})
         setafterRehab({})
         setaverageHome({})
      }
   }, [stat])

   const isElementXPercentInViewport = (el, percentVisible) => {
      let
         rect = el.getBoundingClientRect(),
         windowHeight = (window.innerHeight || document.documentElement.clientHeight);
      return !(
         Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
         Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      )
   };

   return (
      <div className="greatTimeToSellContainer">
         <div className="greatTimeContent text-center">
            <div className="titleText mt-3">
               {title}
            </div>
            <div className="description py-4" >
               {description}
            </div>
            <div className="d-flex justify-content-between pb-5 pt-3 numberDiv" >
               {stat.map((val, i) => {
                  return (
                     <div key={i} className="d-flex flex-column align-items-center">
                        <div className="d-flex">
                           <div className={`numberText numberText${i + 1}`}>
                              {i === 2 ? "$" : null}
                              <Odometer
                                 format=",ddd"
                                 duration={1000}
                                 value={i === 0 ? houseSold : i === 1 ? averageHome : afterRehab}
                              />
                              k
                           </div>
                        </div>
                        <div className="numberDescription pt-3">{val.description}</div>
                     </div>
                  )
               })}
            </div>
         </div>
      </div>
   )
}

export default GreatTimeToSell