import * as React from "react"
import ProgressBar from 'react-bootstrap/ProgressBar'   

const ProgressBarComponent = (props) => {
  return (
    <div className="p-4">
      <ProgressBar  now={props.now} />
    </div>
  );
};

export default ProgressBarComponent;
