import * as React from "react"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import _kebabCase from "lodash/kebabCase"
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";

import Services from "./common/services"
import PromiseSection from "./promiseSection"
import QuickTip from "./common/quickTip"
import Help from "./help"
import "../styles/main.scss"
import Header from "./header"
import Seo from "./seo"
import BackgroundSection from "./common/bgImage"
import CardsSection from "./competitorComparison"
import GreatTimeToSell from "./common/greatTimeToSell";
import Footer from "./footer"
import Faq from "./FAQ"
import SellHome from "./sellHome"
import Testimonials from "./testimonials"
import { BLOG_SCREEN, OFFER_STEP_PROPERTY_DETAILS, OFFER_STEP_SECOND_SCREEN, PRIVACY_POLICY } from "../constant"
import ProgressBar from "../components/common/form/ProgressBar"

const Layout = ({
  children,
  title,
  locationData = {},
  locationPath = false,
  postData = {},
  singlePost = false,
  categoryData = {},
  homepage = false
}) => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      menImageCrop: file(relativePath: { eq: "menImageCrop.png" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
     } 
     rateImg: file(relativePath: { eq: "rateImg.jpg" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
      allStrapiIntroSection {
        nodes {
          SEO {
            metaDescription
            metaTitle
            structuredData
          }
          testimonialEnabled
          chooseTarekEnabled
          FAQEnabled
          animationEffect
          locationSectionAnimation
        }
      }
      allStrapiProcesses {
        nodes {
          SEO {
            metaDescription
            metaTitle
            structuredData
          }
			    animationEffect
          updated_at
          published_at
        }
      }
      faq: allStrapiFaq {
        nodes {
          Question
          Answer
          seo {
            metaTitle
            metaDescription
          }
        }
      }
      allStrapiPrivacyPolicy {
        nodes {
          seo {
            metaTitle
            metaDescription
          }
          updated_at
          published_at
        }
      }
      allStrapiBlogContent {
        nodes {
          seo {
            metaDescription
            metaTitle
          }
          updated_at
          published_at
        }
      }
    }
  `)
  let value = {}
  let image = {}
  let animationEffect = false
  let locationSectionAnimation = data.allStrapiIntroSection.nodes[0].locationSectionAnimation
  let organizationSchema = {
    "@type": "Organization",
    "name": "TarekBuysHouses",
    "url": process.env.GATSBY_URL,
    "sameAs": [
      "https://www.facebook.com/tarekbuyshouses",
      "https://www.instagram.com/tarekbuyshousesllc",
      "https://twitter.com/tarekbuyshouses",
    ],
    "mainEntityOfPage": {
      "@type": "Website",
      "@id": `${process.env.GATSBY_URL}/#webpage`,
      "url": `${process.env.GATSBY_URL}`
    },
    "headline": data.allStrapiIntroSection.nodes[0].SEO.metaTitle,
    "description": data.allStrapiIntroSection.nodes[0].SEO.metaDescription,
  }
  const breadcrumb = (child, name) => {
    return {
      "@type": "BreadcrumbList",
      "@id": `${process.env.GATSBY_URL}${child}/#breadcrumb`,
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@type": "WebPage",
            "@id": process.env.GATSBY_URL,
            "url": process.env.GATSBY_URL,
            "name": "Home"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}${child}`,
            "url": `${process.env.GATSBY_URL}${child}`,
            "name": name
          }
        }
      ]
    }
  }
  if (title === "home") {
    let faqStructure = []
    data.faq.nodes.map(val => {
      faqStructure = [...faqStructure, {
        "@type": "Question",
        "name": val.Question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": val.Answer
        }
      }]
      return faqStructure
    })
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "FAQPage",
            "mainEntity": faqStructure
          }
        ]
    }
    value = {
      ...data.allStrapiIntroSection.nodes[0].SEO,
      structuredData: schema
    }
    animationEffect = data.allStrapiIntroSection.nodes[0].animationEffect
    image = data.menImageCrop.childImageSharp.resize
  } else if (title === "get-offer" || title === "get-offer-no-background") {
    image = data.menImageCrop.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}${location.pathname}#webpage`,
            "url": `${process.env.GATSBY_URL}${location.pathname}`,
            "name": `Get Offer Now`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "description": `We pay cash for houses no matter how bad the condition. Contact us today for no-obligation Cash Offer for your house.`,
            "inLanguage": "en-US",
          },
          breadcrumb(`${location.pathname}`, "Get Offer Now")
        ]
    }
    value = {
      metaTitle: "Get Offer Now - Tarek Buys Houses",
      metaDescription: "We pay cash for houses no matter how bad the condition. Contact us today for no-obligation Cash Offer for your house.",
      structuredData: schema
    }
  } else if (title === "our-process") {
    image = data.rateImg.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}/our-process/#webpage`,
            "url": `${process.env.GATSBY_URL}/our-process`,
            "name": `${data.allStrapiProcesses.nodes[0].SEO.metaTitle}`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "datePublished": `${data.allStrapiProcesses.nodes[0].published_at}`,
            "dateModified": `${data.allStrapiProcesses.nodes[0].updated_at}`,
            "description": `${data.allStrapiProcesses.nodes[0].SEO.metaDescription}`,
            "inLanguage": "en-US",
          },
          breadcrumb("/our-process", "Our Process")
        ]
    }
    value = {
      ...data.allStrapiProcesses.nodes[0].SEO,
      structuredData: schema
    }
    animationEffect = data.allStrapiProcesses.nodes[0].animationEffect
  } else if (title === "singlePost") {
    let schema = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "name": "TarekBuysHouses",
      "url": process.env.GATSBY_URL,
      "sameAs": [
        "https://www.facebook.com/tarekbuyshouses",
        "https://www.instagram.com/tarekbuyshousesllc",
        "https://twitter.com/tarekbuyshouses",
      ],
      "mainEntityOfPage": {
        "@type": "Article",
        "@id": `${process.env.GATSBY_URL}/${postData.categories[0].slug}/${_kebabCase(postData.slug.toLowerCase())}/`,
        "author": {
          "@type": "Person",
          "name": "Team TBH",
          "url": `${process.env.GATSBY_URL}/${postData.categories[0].slug}/${_kebabCase(postData.slug.toLowerCase())}/`,
        },
        "headline": postData.Title,
        "image": {
          "@type": "imageObject",
          "url": `${process.env.GATSBY_URL}${postData.blogImage.localFile.publicURL}`,
          "height": "600",
          "width": "800"
        },
        "datePublished": postData.published_at,
        "dateModified": postData.updated_at
      },
      "author": {
        "@type": "Person",
        "name": "Team TBH",
        "url": `${process.env.GATSBY_URL}/${postData.categories[0].slug}/${_kebabCase(postData.slug.toLowerCase())}/`,
      },
      "headline": postData.Title,
      "description": postData.Subtitle,
      "image": {
        "@type": "imageObject",
        "url": `${process.env.GATSBY_URL}${postData.blogImage.localFile.publicURL}`,
        "height": "600",
        "width": "800"
      },
      "datePublished": postData.published_at,
      "dateModified": postData.updated_at
    }
    image = postData.blogImage.localFile.childImageSharp.resize
    value = {
      ...postData.SEO,
      structuredData: schema
    }
  } else if (title === "Category") {
    image = data.menImageCrop.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}/${_kebabCase(categoryData.slug.toLowerCase())}/#webpage`,
            "url": `${process.env.GATSBY_URL}/${_kebabCase(categoryData.slug.toLowerCase())}/`,
            "name": `${categoryData.CategoryName}`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "datePublished": `${categoryData.published_at}`,
            "dateModified": `${categoryData.updated_at}`,
            "description": `${categoryData.CategoryDescription}`,
            "inLanguage": "en-US",
          },
          breadcrumb(`/${_kebabCase(categoryData.slug.toLowerCase())}`, `${categoryData.CategoryName.toLowerCase()}/`)
        ]
    }
    value = {
      ...categoryData.SEO,
      structuredData: schema
    }
  } else if (locationPath) {
    animationEffect = locationData.locationDetails.animationEffect
    image = locationData.locationDetails.location_image.localFile.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}/${locationData.locationDetails.slug}/#webpage`,
            "url": `${process.env.GATSBY_URL}/${locationData.locationDetails.slug}`,
            "name": `${locationData.locationDetails.SEO.metaTitle}`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "datePublished": `${locationData.locationDetails.published_at}`,
            "dateModified": `${locationData.locationDetails.updated_at}`,
            "description": `${locationData.locationDetails.SEO.metaDescription}`,
            "inLanguage": "en-US",
          },
          breadcrumb(`/${locationData.locationDetails.slug}`, locationData.locationDetails.location_name)
        ]
    }
    value = {
      ...locationData.locationDetails.SEO,
      structuredData: schema
    }
  } else if (title === "Blog") {
    image = data.menImageCrop.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}/blog/#webpage`,
            "url": `${process.env.GATSBY_URL}/blog/`,
            "name": `${data.allStrapiBlogContent.nodes[0].seo.metaTitle}`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "datePublished": `${data.allStrapiBlogContent.nodes[0].published_at}`,
            "dateModified": `${data.allStrapiBlogContent.nodes[0].updated_at}`,
            "description": `${data.allStrapiBlogContent.nodes[0].seo.metaDescription}`,
            "inLanguage": "en-US",
          },
          breadcrumb(BLOG_SCREEN, "Blog")
        ]
    }
    value = {
      ...data.allStrapiBlogContent.nodes[0].seo,
      structuredData: schema
    }
  } else if (title === "privacy-policy") {
    image = data.menImageCrop.childImageSharp.resize
    let schema = {
      "@context": "https://schema.org",
      "@graph":
        [
          organizationSchema,
          {
            "@type": "WebPage",
            "@id": `${process.env.GATSBY_URL}/privacy-policy/#webpage`,
            "url": `${process.env.GATSBY_URL}/privacy-policy/`,
            "name": `${data.allStrapiPrivacyPolicy.nodes[0].seo.metaTitle}`,
            "isPartOf": {
              "@id": `${process.env.GATSBY_URL}/#website`
            },
            "datePublished": `${data.allStrapiPrivacyPolicy.nodes[0].published_at}`,
            "dateModified": `${data.allStrapiPrivacyPolicy.nodes[0].updated_at}`,
            "description": `${data.allStrapiPrivacyPolicy.nodes[0].seo.metaDescription}`,
            "inLanguage": "en-US",
          },
          breadcrumb(PRIVACY_POLICY, "Privacy Policy")
        ]
    }
    value = {
      ...data.allStrapiPrivacyPolicy.nodes[0].seo,
      structuredData: schema
    }
  } else if (title === 'faq'){
    value = data.faq.nodes[0].seo
    image = data.menImageCrop.childImageSharp.resize
  } else {
    image = data.menImageCrop.childImageSharp.resize
    value = data.allStrapiIntroSection.nodes[0].SEO
  }
  let locationQuickTip = {}
  let helpContent = {}
  let repairContent = {}
  let ourMission = []
  let locationDesc = []
  let locationHelpQuestion = []
  if (locationPath) {
    locationHelpQuestion = locationData.locationDetails.help_question
    locationQuickTip = []
    // locationQuickTip = locationData.locationDetails.locationQuickTip
    helpContent = locationData.allStrapiLocationCommonContent.nodes[0].help_content[0]
    repairContent = locationData.allStrapiLocationCommonContent.nodes[0].repair_content
    ourMission = !_isEmpty(locationData.locationDetails.our_mission) ? locationData.locationDetails.our_mission : locationData.allStrapiLocationCommonContent.nodes[0].our_mission
    locationDesc = !_isEmpty(locationData.locationDetails.our_mission_description) ? locationData.locationDetails.our_mission_description : locationData.allStrapiLocationCommonContent.nodes[0].our_mission_description
  }
  const { metaDescription, metaTitle, structuredData = {}, testimonialEnabled = true, chooseTarekEnabled = true, FAQEnabled = true } = value
  const titleArray = ["Blog", "Category", "our-process", "singlePost", "privacy-policy", "get-offer-no-background", 'faq', 'terms-and-conditions','about-us']
  const Array = [OFFER_STEP_SECOND_SCREEN, OFFER_STEP_PROPERTY_DETAILS]
  return (
    <div className="main-container">
      <Seo title={metaTitle} description={metaDescription} structuredData={structuredData} pathname={location.pathname} metaImage={image} />
      <Header title={title} />
      {_includes(Array, location.pathname) ? (
        <ProgressBar
          now={
            OFFER_STEP_SECOND_SCREEN.includes(location.pathname)
              ? "10"
              : OFFER_STEP_PROPERTY_DETAILS.includes(location.pathname)
                ? "60"
                : "100"
          }
        />
      ) : null}
      {titleArray.includes(title) ? (
        <>{children}</>
      ) : title === "get-offer" ? (
        <BackgroundSection>
          <div
            className="getOfferBgHeight d-flex"
          >
            {children}
          </div>
        </BackgroundSection>) : (
        <>
          <BackgroundSection
            className={
              locationPath ? "bg-image-cover-location" : "bg-image-cover bg-opacity"
            }
            locationData={locationData}
            locationPath={locationPath}
          >
            <div
              className={locationPath ? "locaotion-bg" : ""}
              style={{
                minHeight: "60vh",
                backgroundColor: locationPath ? "#00000066" : "transparent",
              }}
            >
              {children}
            </div>
          </BackgroundSection>
          <SellHome
            locationData={locationData}
            locationPath={locationPath}
          />
          {
            locationPath ?
              <>
                <PromiseSection introText={locationData.allStrapiLocationCommonContent.nodes[0].intro_text} locationPath={locationPath} animationEffect={animationEffect} />
                <Testimonials
                  locationData={locationData}
                  locationPath={locationPath}
                  animationEffect={animationEffect}
                />
                <CardsSection
                  locationData={locationData}
                  locationPath={locationPath}
                />
                <GreatTimeToSell locationData={locationData} />
                <QuickTip QuickTipsHelp={locationQuickTip} locationPath={locationPath} locationData={locationData} animationEffect={animationEffect} />
                <Help data={helpContent} locationHelpQuestion={locationHelpQuestion} repairData={repairContent} locationPath={locationPath} animationEffect={animationEffect} />
                {/* <Services data={stepContent[0].step} description={true} sellContent={true} /> */}
                <Services data={ourMission[0].step} title={ourMission[0].title} description={true} locationDesc={locationDesc} locationPath={locationPath} animationEffect={animationEffect} />
                <Faq animationEffect={animationEffect} />
              </> :
              <>
                {testimonialEnabled && <Testimonials animationEffect={animationEffect} />}
                {chooseTarekEnabled && <CardsSection />}
                {FAQEnabled && <Faq animationEffect={animationEffect} />}
              </>
          }
        </>
      )}
      {/* {newsLetterArray.includes(location.pathname) ? "" : !singlePost && <NewsLetter />} */}
      <Footer title={title} animationEffect={animationEffect} locationSectionAnimation={locationSectionAnimation} homepage={homepage} />
    </div>
  )
}

export default Layout
