import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { RiCloseLine } from "react-icons/ri";
import ScrollReveal from 'scrollreveal'
import _includes from "lodash/includes"

import { ANIMATION_DURATION, HOME_SCREEN } from "../constant"
import { getStorage } from "../utils"
import ButtonField from "./common/form/ButtonField"
import request from "../services/request"

const NewsLetter = ({ title = "", animationEffect = false }) => {
  const [modalShow, setModalShow] = useState(false)
  const [newsLetterEmail, setEmail] = useState("")

  useEffect(() => {
    let val = ["Blog", "Category", "singlePost", "privacy-policy"]
    if (!_includes(val, title) && animationEffect) {
      ScrollReveal().reveal('.newsLetterContent', { delay: ANIMATION_DURATION });
    }
  }, [title, animationEffect])

  const onChange = e => {
    setEmail(e.target.value)
  }
  const onSubmit = e => {
    e.preventDefault()
    let initalValue = getStorage("formData")
    let finalValue = { ...initalValue, email: newsLetterEmail }
    const {
      email = null,
      utm_source = null,
      utm_medium = null,
      utm_campaign = null,
      utm_network = null,
      utm_keyword = null,
      utm_landing = null,
      utm_device = null,
      utm_creative = null,
      utm_location = null,
      utm_content = null,
      utm_term = null,
    } = finalValue
    let data = {
      email,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_network,
      utm_keyword,
      utm_landing,
      utm_device,
      utm_creative,
      utm_location,
      utm_content,
      utm_term,
      clientId: window.ga.getAll()[0].get("clientId"),
    }
    request({
      url: "brb3ok4",
      data,
    })
      .then(() => {
        console.log('NewsLetter');
      })
      .catch(err => {
        console.log("onConfirm-re", err)
      })
    request({
      url: "ovp2cq0",
      data,
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'newsletter_submit',
          'dtL_newsletter_submit': {
            'email': email,
            branch: process.env.GATSBY_BRANCH
          }
        });
        setModalShow(true)
        document.getElementById("mail").value = ""
      })
      .catch(err => {
        console.log("onConfirm-re", err)
      })
  }
  const onClose = () => {
    setModalShow(false)
    navigate(HOME_SCREEN)
  }
  const MyVerticallyCenteredModal = props => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter p-5"
        centered
        onHide={() => onClose()}
        className="newsLetterModel"
      >
        <div
          style={{ height: 250 }}
          className="thanks-card d-flex justify-content-center align-items-center flex-column px-3 text-center text-white"
        >
          <h4>Thanks for joining our newsletter!</h4>
        </div>
        <Link
          to={HOME_SCREEN}
          className="close-btn position-absolute d-flex align-items-center justify-content-center"
          onClick={() => onClose()}
        >
          <RiCloseLine size={25} color="#7f7f7f" />
        </Link>
      </Modal>
    )
  }
  return (
    <div className="newsLetterContainer">
      <div className="newsLetterContent">
        <div className="menDisplay">
          <StaticImage
            src="../images/menImage.png"
            alt="Tarek El Moussa"
            height={320}
            width={440}
          />
        </div>
        <h2>
          Join Our Email Newsletter
        </h2>
        <div className="subHeader text-center">Join thousands of homeowners nationwide. keep up to date with the latest tips on how sell your property no matter the condition</div>
        <form
          className="position-relative mt-3 w-55 input subcribeButton"
          onSubmit={e => onSubmit(e)}
        >
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <ButtonField
            className="btn-primary getOfferBtn subscribeBtn"
            type="submit"
            id="subscribeBtn"
            fieldName="subscribe"
            name="SUBSCRIBE"
          />
          <input
            id="mail"
            type="email"
            required={true}
            placeholder="Enter your email address"
            className="form-control form-getAddressFixedInput shadow-sm"
            name="subscribeText"
            onChange={e => onChange(e)}
            autoComplete="off"
          />
        </form>
        <div className="menDisplayMobile">
          <StaticImage
            src="../images/menImage.png"
            alt="Tarek El Moussa"
            height={320}
            width={500}
          />
        </div>
      </div>
    </div>
  )
}

export default NewsLetter