import React, { useEffect } from "react"
import ScrollReveal from 'scrollreveal'
import Img from "gatsby-image"

import { graphql, useStaticQuery } from "gatsby"
import { ANIMATION_DURATION } from "../constant"

const PromiseSection = (props) => {
  const { aboutTarakEnabled = true, introText = "", locationPath = false, animationEffect = false } = props
  const data = useStaticQuery(graphql`
    query {
      flipFlop: file(relativePath: { eq: "tarek-promise.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sign: file(relativePath: { eq: "sign.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menImageCrop: file(relativePath: { eq: "menImageCrop.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
    }
  `)
  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.flipFlapSection', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])
  const flipFlop = data.flipFlop.childImageSharp.fluid
  const sign = data.sign.childImageSharp.fluid
  const menImageCrop = data.menImageCrop.childImageSharp.fluid
  return <>{aboutTarakEnabled &&
    <div className="promiseContainer" style={{ background: locationPath ? "#f5f5f5" : "white" }}>
      <div className="max2000">
        <div className={`d-flex justify-content-center flipFlapSection flexColumnReverce ${locationPath ? "pt-3" : ""}`}>
          <div className="manImage">
            <Img fluid={menImageCrop} alt="Tarek El Moussa" />
          </div>
          <div className="d-flex flex-column text-center flipFlopText">
            <div className="font-italic">
              {introText}
            </div>
            <div className="d-flex justify-content-center flexColumn">
              <div className="flipFlopSign">
                <Img fluid={sign} alt="Tarak Sign" />
              </div>
              <div className="flipFlopImages">
                <Img fluid={flipFlop} alt="Tarek Promise" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }</>
}

export default PromiseSection;