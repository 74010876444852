import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import ScrollReveal from 'scrollreveal'

import ButtonField from "./common/form/ButtonField"
import { ANIMATION_DURATION, HOME_SCREEN } from "../constant"
// import ReactMarkdown from "react-markdown"

const Help = ({ data, locationHelpQuestion = [], repairData, helpEnabled = true, repairCostEnabled = true, locationPath = false, animationEffect = false }) => {
  const introData = useStaticQuery(graphql`
    query {
      sellHome: file(relativePath: { eq: "Sell-House-Fast-Cash.jpg" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rateImg: file(relativePath: { eq: "rateImg.jpg" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const [scrollValue, setScrollValue] = useState(0)
  const [contentHeight, setContentHeight] = useState(10)
  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.helpSection', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.repairSection', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.cashOfferAnimation', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])
  useEffect(() => {
    setContentHeight(document.querySelector(".helpContainer").offsetHeight)
    const element = document.querySelector(".helplist");
    element.addEventListener("scroll", onscrollOffset)
    return () => {
      element.removeEventListener("scroll", onscrollOffset)
    }
  }, [scrollValue])

  const onscrollOffset = () => {
    let content = document.querySelector(".helpContainer").offsetHeight
    let val = document.querySelector(".helplist")
    let final = ((content) / 100) * (val.scrollTop / (val.scrollHeight - content)) * 100
    setScrollValue(final === 0 ? 0 : final - 15)
  }

  const rateImg = introData.rateImg.childImageSharp.fluid
  const { Title, help_list } = data
  const { TH1, TH2, repair_question, table_data, title } = repairData[0]
  return (
    <div id="offsetHelp" style={{ background: locationPath ? "white" : "#f5f5f5" }} className="sellHomeContainer helpRepairContainer ourProcessContainer">
      <div className="max2000 py-5">
        {helpEnabled && <div className="helpSection">
          <h2>
            {Title}
          </h2>
          <div className="d-flex justify-content-around p-3 mb-5 helpArea">
            <div className="helpContainer">
              <div>
                <Img fluid={locationHelpQuestion[0].image.localFile.childImageSharp.fluid} className="imgheight" alt={locationHelpQuestion[0].image?.alternativeText}/>
              </div>
              <div className="mt-3 headingCenter">
                {locationHelpQuestion[0].title}
              </div>
              {/* <div className="mt-3 imgText headingCenter">
                <ReactMarkdown>{locationHelpQuestion[0].description}</ReactMarkdown>
              </div> */}
              <div className="text-center"><ButtonField className="LearnMoreBtn requestOfferBtn my-4" to={HOME_SCREEN} name="Get Your Offer" /></div>
            </div>
            <div className="borderLine" style={{ height: `${contentHeight}px` }}>
              <div className="borderDot" style={{ marginTop: `${scrollValue}px` }} />
            </div>
            <div className="descContent helplist" id="helplistID" style={{ height: `${contentHeight}px ` }}>
              {help_list.map((val, i) => {
                return (
                  <div className="d-flex  px-2 py-1" key={i}>
                    <span className="d-flex">
                      <li />
                      <span className="mb-3" style={{ lineHeight: "20px" }}>
                        <span style={{ fontWeight: "bold" }} className="helpText">
                          {val.title}
                        </span>
                        <span className="helpText"> - {val.description}</span>
                      </span>
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>}
        {repairCostEnabled && <div className="repairSection">
          <h2>
            {title}
          </h2>
          <div className="d-flex justify-content-around pt-5 helpArea">
            <div className="helpContainer">
              <div>
                <Img fluid={rateImg} className="imgheight" alt="rate-image" />
              </div>
              <div className="mt-3 headingCenter">
                {repair_question[0].title}
              </div>
              <div className="mt-3 imgText headingCenter">
                {repair_question[0].description}
              </div>
            </div>
            <div className="descContent">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="text-center repairHeading">
                      {TH1}
                    </th>
                    <th scope="col" className="text-center costHeading">
                      {TH2}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {table_data.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td className="tableLable">{val.title}</td>
                        <td className="tableValue">{val.description}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>}
        {/* <div className="text-center mt-3">
          <ButtonField className="btn-primary getOfferBtn locationButtons" onClick={() => navigate(HOME_SCREEN)} name="GET A FREE OFFER" />
        </div> */}
      </div>
      {!locationPath && <div className="cashOffer flexCenter">
        <div className="flex-column cashOfferAnimation">
          <div className="text-center mainTitle px-2">
            Ready To Take The Next Step?
          </div>
          <div className="cashOfferSubtitle">
            Sell Your Home To Tarek El Moussa Today!
          </div>
          <div className="d-flex justify-content-center mt-1">
            <ButtonField className="LearnMoreBtn" name="GET STARTED" to={HOME_SCREEN} />
          </div>
        </div>
      </div>}
    </div>
  )
}

export default Help
