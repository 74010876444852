import React, { useEffect } from "react"
import { useState } from "react"
import { Carousel } from "react-bootstrap"
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import ScrollReveal from 'scrollreveal'

import ButtonField from "./common/form/ButtonField"
import { ANIMATION_DURATION, HOME_SCREEN, iframes } from "../constant"
import { YoutubeModal } from "../components/common/youTubeModal"

const Testimonials = ({ locationData = {}, locationPath = false, animationEffect = false }) => {
  const [modalShow, setModalShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState("")
  const FeedBackData = useStaticQuery(graphql`
    query {
      allStrapiFeedBack {
        nodes {
          FeedbackText
          Title
          clientNames
          strapiId
        }
      }
      googleReviewLocation: file(relativePath: { eq: "locationReview.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.boxShadow', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.carousel-container', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.locationWrap', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])
  const carouselArea = () => {
    return (
      <div
        className={
          locationPath === false
            ? "carousel-container max2000"
            : "carousel-container-location"
        }
      >
        <Carousel
          interval={null}
          prevIcon={<AiFillCaretLeft color={"#d9821b"} size={50} />}
          nextIcon={<AiFillCaretRight color={"#d9821b"} size={50} />}
          style={{ width: "100% !important" }}
        >
          {FeedBackData.allStrapiFeedBack.nodes.map((seller, key) => {
            return (
              <Carousel.Item key={key} className="mt-5 carousel-item">
                <div
                  className={
                    locationPath === false
                      ? "carousel-caption-area"
                      : "carousel-caption-area-location"
                  }
                >
                  <Carousel.Caption className={locationPath ? "carousel-caption-location" : ""}>
                    <div className="f-25 carousel-title">
                      "{seller.Title}"
                      <hr className="solid carousel-title-underline" />
                    </div>
                    <div
                      className="mt-4 mb-4 carousel-sub-title"
                      style={{
                        margin: "auto",
                      }}
                    >
                      {seller.FeedbackText}
                    </div>
                    <div className="mt-4 mb-4 carousel-seller">
                      <h5>{seller.clientNames}</h5>
                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            )
          })}
        </Carousel>
      </div>
    )
  }

  const googleReviewLoc = FeedBackData.googleReviewLocation.childImageSharp.fluid
  return (
    <div className="testimonialsArea">
      <div className="testimonials-bubble">
        <h2>{locationPath ? `${locationData.locationDetails.testimonialTitle}` : "Homeowners love working with Tarek Buys Houses!..."} </h2>
      </div>
      {locationPath === false ? (
        <div>
          <div className="mt-5 mb-3 d-flex flex-wrap flex-row justify-content-around max2000">
            {iframes.map((iframe, i) => {
              return (
                <div key={i} className="boxShadow rounded">
                  <iframe
                    title="iframe"
                    className="responsive-iframe"
                    srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style><a><img src=https://img.youtube.com/vi/${iframe.id}/hqdefault.jpg></a>`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <span
                    role="none"
                    onKeyDown={() => ""}
                    onClick={() => {
                      setVideoUrl(iframe.url)
                      setModalShow(true)
                    }}
                    className="playBotton cursor-pointer"
                  >
                    ▶
                  </span>
                </div>
              )
            })}
          </div>
          {carouselArea()}
        </div>
      ) : (
        <div className="d-flex flex-row locationWrap justify-content-around align-items-center max2000">
          <div className="mt-5 marginBottom d-flex flex-wrap flex-row">
            <div className="boxShadow-location rounded">
              <iframe
                title="youtube"
                className="responsive-iframe-location"
                srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style><a><img src=https://img.youtube.com/vi/${iframes[1].id}/hqdefault.jpg></a>`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <span
                role="none"
                onKeyDown={() => ""}
                onClick={() => {
                  setVideoUrl(iframes[1].url)
                  setModalShow(true)
                }}
                className="playBotton cursor-pointer"
              >
                ▶
              </span>
            </div>
          </div>
          {carouselArea()}
        </div>
      )}
      {locationPath ?
        <>
          <div className="text-center">
            <ButtonField className="btn-primary header-get-offer getOfferBtn locationButtons" to={HOME_SCREEN} name="GET MY FREE OFFER" />
          </div>
          <div className="google-business-reviews-location pt-3">
            <Img
              fluid={googleReviewLoc}
              style={{ height: 47, width: 200, margin: "auto" }}
              alt="Google Review"
            />
          </div>
        </>
        : <div className="google-business-reviews pt-3">
          <Img
            fluid={googleReviewLoc}
            style={{ height: 47, width: 200, margin: "auto" }}
            alt="Google Review"
          />
        </div>
      }
      <YoutubeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl={videoUrl}
        className="testimonialModel"
      />
    </div>
  )
}
export default Testimonials
