import React, { useEffect } from "react"
import ScrollReveal from 'scrollreveal'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import { ANIMATION_DURATION } from "../../constant"

const OurMission = props => {
  const { title = "", data = [], description = false, sellContent = false, ourMissionSection = false, locationDesc = [], locationPath = false, animationEffect = false, homepage = false } = props

  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.ourMissionSection', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.columnFlex', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])

  return (
    <div className="ourMissionSection">
      <div className={`width`}>
        {title !== "" && (
          <h2 className={homepage ? "pt-0 d-none" : ""}>{title}</h2>
        )}
        {locationPath ? locationDesc.map((val, i) => {
          return <div className={`locationDesc text-center px-4 my-5`} key={i}>
            <ReactMarkdown>
              {val.desc}
            </ReactMarkdown>
          </div>
        }) : ""}
        <div className="d-flex justify-content-around align-items-baseline pb-5 columnFlex">
          {data.map((val, i) => (
            <div
              className={`mx-2 mt-4 d-flex flex-column align-items-center"`}
              key={i}
            >
              <div className="d-flex flex-column align-items-center ">
                <div>
                  {!ourMissionSection && !locationPath && <div className="circle">{i + 1}</div>}
                  <Img fluid={val.image.localFile.childImageSharp.fluid} className="Images" alt={val.image?.alternativeText} />
                  <div className="imageText">{val.title}</div>
                </div>
                {description ? (
                  <div
                    className={`ourMissionDes ${homepage ? "mb-4" : "mb-5"} mx-3 text-center ${sellContent ? "sellContentImg" : ""}`}
                  >
                    {val.description}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default OurMission
