import React, { useEffect } from "react"
import { Accordion, Card } from "react-bootstrap"
import { FiArrowDown } from "react-icons/fi"
import { graphql, useStaticQuery } from "gatsby"
import ScrollReveal from 'scrollreveal'

import BackgroundSection from "./common/bgImage"
import { ANIMATION_DURATION } from "../constant"

const FAQ = ({ animationEffect = false }) => {
  const FAQdata = useStaticQuery(graphql`
    query {
      allStrapiFaq {
        nodes {
          questionAnswer {
            Answer
            Question
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.content', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])

  const { nodes } = FAQdata.allStrapiFaq
  return (
    <div>
      <BackgroundSection className="bg-image-cover" alt="home-image">
        <div className="FAQcontainer">
          <div className="content">
            <h2>
              Frequently Asked Questions
            </h2>
            <Accordion defaultActiveKey="0">
              {nodes[0].questionAnswer.filter((item, index) => index < 6).map((value, key) => {
                return (
                  <Card className="mt-4" key={key}>
                    <Accordion.Toggle
                      as={Card.Header}
                      className="card-header d-flex justify-content-between text-center cursor-pointer"
                      eventKey={key.toString()}
                    >
                      <div className="text-center w-100 textcolor flexCenter">
                        {value.Question}
                      </div>
                      <FiArrowDown className="arrow-style mx-2" />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      className="card-answer text-white text-center"
                      eventKey={key.toString()}
                    >
                      <Card.Body>{value.Answer}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </div>
        </div>
      </BackgroundSection>
    </div>
  )
}

export default FAQ
