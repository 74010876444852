const request = ({ url = "", method = "POST", data = {} }) => {
  return fetch(`https://hooks.zapier.com/hooks/catch/6114798/${url}`, { method, body: JSON.stringify(data), })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log('err', err)
    });
}
export const fullRequest = ({ url = "", method = "POST", data = {} }) => {
  return fetch(url, { method, body: JSON.stringify(data), })
    .then((resp) => resp.json())
    .then((res) => {
      return res;
    }).catch((err) => {
      console.log('err', err)
    });
}

export default request;