import * as React from "react"
import { Link } from "gatsby"

const ButtonField = (props) => {
  const { className = "", name = "", type = "button", id = "", onClick = () => { }, fieldName = "", disabled = false, to = "" } = props

  const onClickBtn=(e)=>{
    e.preventDefault()
    return onClick()
  }

  return (
    <div className="buttonContainer">
      {type === "submit" ?
        <button type={type} name={fieldName} id={id} className={`btn ${className}`} onClick={onClick} disabled={disabled} >{name}</button>
        : <div onClick={onClickBtn} role="none">
          <Link type={type} to={to} name={fieldName} id={id} className={`btn ${className}`} disabled={disabled} >{name}</Link>
        </div>
      }
    </div>
  );
}

export default ButtonField;