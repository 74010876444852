import React, { useEffect } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import ScrollReveal from 'scrollreveal'

import ButtonField from "./common/form/ButtonField"
import { ANIMATION_DURATION, PROCESS_SCREEN } from "../constant"

const CardsSection = ({ locationData = {}, locationPath = false }) => {
  const data = useStaticQuery(graphql`
    query {
      chooseContent: allStrapiIntroSection {
        nodes {
          chooseType {
            title
            list {
              Category
            }
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          chooseTitle {
            description
            title
          }
          whyChooseTarek {
            title
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          animationEffect
        }
      }
    }
  `)
  let value = data.chooseContent.nodes[0]
  // let sellHome = {}
  // let sellHomeLeft = {}
  let tarekChoose = value.chooseType[1]
  if (locationPath) {
    // sellHome = locationData.locationDetails.whyChooseRightImage.localFile.childImageSharp.fluid
    // sellHomeLeft = locationData.locationDetails.whyChooseLeftImage.localFile.childImageSharp.fluid
    value = locationData.locationDetails
    tarekChoose = value.chooseType[1]
  } else {
    value = data.chooseContent.nodes[0]
  }
  useEffect(() => {
    if (value.animationEffect) {
      ScrollReveal().reveal('.cardText', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.homeSellContent', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.cardContent', { delay: ANIMATION_DURATION });
      ScrollReveal().reveal('.cardContent', { delay: ANIMATION_DURATION });
    }
  }, [value.animationEffect])
  const sideBox = (chooseType) => {
    return <div className="sideBox">
      <div className="sideCardHeader flexCenter">{chooseType.title}</div>
      <div className="pointsList">
        {chooseType.list.map((val, i) => {
          return (
            <div
              className="d-flex  align-items-center sideCardDiv px-2 py-1"
              key={i}
            >
              <div className="minusImage">
                <Img fluid={chooseType.image.localFile.childImageSharp.fluid} alt={chooseType.image.alternativeText} />
              </div>
              <span className="w-100 sideCardText">{val.Category}</span>
            </div>
          )
        })}
      </div>
    </div>
  }
  const { chooseTitle, chooseType } = value

  return (
    <>
      <div className="cardContainer d-flex flex-column align-items-center py-5">
        <h2>
          {chooseTitle[0].title}
        </h2>
        <div className="cardText text-center mb-4">
          {chooseTitle[0].description}
        </div>
        {!locationPath && <div className="row d-flex flex-row homeSellContent justify-content-between mb-5 mr-0">
          {data.chooseContent.nodes[0].whyChooseTarek.map((val, key) => {
            return (
              <div key={key} className="text-center col-12 col-sm-4 mt-3 p-3">
                <Img fluid={val.image.localFile.childImageSharp.fluid} alt={val.image.alternativeText} className="sixImages" />
                <div className="d-flex flex-column justify-content-end align-items-center">
                  <div className="ourMissionDes">{val.title}</div>
                </div>
              </div>
            );
          })}
        </div>}
        <div className="cardContent w-100 d-flex justify-content-center max2000">
          {/* {locationPath ? (<Img fluid={sellHomeLeft} className="sideBoxImage topImage" />) :  */}
          {sideBox(chooseType[0])}
          <div className="centerCardBox">
            <div className="centerCardContent card-4">
              <div className="cardHeader flexCenter">{tarekChoose.title}</div>
              <div>
                {tarekChoose.list.map((val, i) => {
                  return (
                    <div className="d-flex  align-items-center cardDiv" key={i}>
                      <div className="correctImg">
                        <Img fluid={tarekChoose.image.localFile.childImageSharp.fluid} alt={tarekChoose.image.alternativeText} />
                      </div>
                      <span className="w-100 centerCardText">{val.Category}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {/* {locationPath ? (<Img fluid={sellHome} className="sideBoxImage bottomImage" />) :  */}
          {sideBox(chooseType[2])}
        </div>
        <ButtonField className="LearnMoreBtn my-5" name="Learn More" to={PROCESS_SCREEN} />
      </div>
      <div id="offsetFAQ" />
    </>
  )
}
export default CardsSection